import React, { memo, PropsWithChildren, useContext, useEffect, useMemo } from "react";

import { v4 as uuid } from "uuid";

import { FormField } from "models/Form";
import { FormValues, IDataValue } from "models/FormRecord";
import { useAppDispatch, useAppSelector } from "store";
import { actions as historyActions } from "store/slices/history";

import FormPageHeader from "./FormPageHeader";
import FormPageContent from "./FormPageContent";
import FormPageRepeatable from "./FormPageRepeatable";
import { getEmail } from "../../../../../utils/getEmail";
import { updateRepeatableAdditionalData as addRepeatableUpdatedAtUpdatedBy } from "./addRepeatableUpdatedAtUpdatedBy";
import { FormContext } from "../../hooks/useSmartForm";
import { SubmitFormFn } from "views/RecordView/RecordView";

interface IProps {
	field?: FormField;
	childrenFields: FormField[];
	onSubmit: SubmitFormFn;
	setFilterModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isRepeatableItemPage?: boolean;
}
type PropsType = IProps;

const FormPage: React.FC<PropsType> = (props) => {
	const { field, childrenFields, onSubmit, setFilterModalIsOpen, isRepeatableItemPage = false } = props;

	const dispatch = useAppDispatch();
	const historySlice = useAppSelector((store) => store.history);
	const isFrozen = useAppSelector((store) => store.form.isFrozen);
	const fieldReferenceLibrary = useAppSelector((store) => store.form.fieldReferenceLibrary);
	const ctx = useContext(FormContext);

	const siblingTypes = useMemo(() => {
		if (field) {
			return field.children?.map((it) => it.type) || [];
		} else {
			return childrenFields.map((it) => it.type);
		}
	}, [field, childrenFields]);

	const isRepeatableTable = field && field.type === "repeatableGroup";

	const isActiveRepeatableItem =
		field && field.type === "repeatableGroup" && field.name === historySlice.list[historySlice.list.length - 2]; // the parent of the active element in history is the one specified

	const isInlineGroup = field && field.type === "inlineGroup";

	useEffect(() => {
		if (isInlineGroup && historySlice.active === field.name) {
			dispatch(historyActions.popHistory());
		}
	}, [dispatch, historySlice]);

	const handleSave = async (exit = false, stay = true) => {
		if (!ctx) return;
		const user = await getEmail();
		await ctx.trigger(undefined, { shouldFocus: true }).then(console.log).catch(console.log);
		addRepeatableUpdatedAtUpdatedBy(ctx.changes, fieldReferenceLibrary, ctx.applyChange, user);

		await ctx.handleSubmit(async (values) => {
			// Set all new defaults to the current values and saves them to disk
			ctx.reset(values);
			await onSubmit(
				values,
				ctx.changes,
				historySlice.list,
				{ exit, stay, completed: ctx.engine.getIsCompleted() },
				!isRepeatableTable,
			);
		}, console.error)();
	};

	const handleAddItem = async () => {
		if (!ctx) return;
		const user = await getEmail();
		const fullPathName = historySlice.list.join(".");
		const noItems = (ctx.getValues(fullPathName) || []).length;
		const now = new Date().toISOString();

		if (user) {
			ctx.applyChange(`${fullPathName}.${noItems}`, { id: uuid(), _date_created: now, _created_by: user });
		} else {
			ctx.applyChange(`${fullPathName}.${noItems}`, { id: uuid(), _date_created: now });
		}

		addRepeatableUpdatedAtUpdatedBy([`${fullPathName}.${noItems}`], fieldReferenceLibrary, ctx.applyChange, user);
		await handleSave();
		await dispatch(historyActions.pushHistory(`${noItems}`));
	};

	const handleDuplicateItem = async (e: React.MouseEvent, id: string) => {
		e.stopPropagation();
		if (!ctx) return;
		const user = await getEmail();

		const absolutePath = historySlice.absolutePath;
		const items = ([...ctx.getValues(absolutePath)] || []) as FormValues[];
		const currentItem = items.find((it) => it.id === id);
		if (currentItem) {
			const now = new Date().toISOString();
			const currentItemDuplicated = field?.children?.reduce((acc, currentField) => {
				if (currentField.type === "images" || currentField.type === "files") {
					return acc;
				}
				acc[currentField.name] = currentItem[currentField.name];
				return acc;
			}, {} as Record<string, IDataValue>);
			const additionalData: Record<string, string> = { id: uuid(), _date_created: now };
			if (user) {
				additionalData._created_by = user;
			}

			const noItems = (ctx.getValues(absolutePath) || []).length;
			ctx.applyChange(`${absolutePath}.${noItems}`, {
				...currentItemDuplicated,
				...additionalData,
			});
			addRepeatableUpdatedAtUpdatedBy(
				[`${absolutePath}.${items.length}`],
				fieldReferenceLibrary,
				ctx.applyChange,
				user,
			);
			await handleSave();
			await dispatch(historyActions.pushHistory(`${items.length}`));
		}
	};

	const shouldRender = () => {
		// Root page
		if (!field && historySlice.active === undefined) return true;
		// Active page
		if (field && historySlice.active === field.name) return true;
		// Active repeatable item
		if (isActiveRepeatableItem) return true;
		// Otherwise
		return false;
	};

	if (!shouldRender()) return <React.Fragment />;
	return (
		<>
			<div
				style={{
					position: "absolute",
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "column",
					overflowY: "auto",
					overscrollBehavior: "none",
				}}
			>
				{!isRepeatableTable && <FormPageHeader field={field} siblingTypes={siblingTypes} />}
				{isRepeatableTable && !isRepeatableItemPage ? (
					<FormPageRepeatable
						field={field}
						onSubmit={onSubmit}
						setFilterModalIsOpen={setFilterModalIsOpen}
						isFrozen={isFrozen}
						handleDuplicateItem={handleDuplicateItem}
						handleAddItem={handleAddItem}
						handleSave={handleSave}
					/>
				) : (
					<FormPageContent
						childrenFields={childrenFields}
						isFrozen={isFrozen}
						siblingTypes={siblingTypes}
						handleSave={handleSave}
					/>
				)}
			</div>
			{!isRepeatableItemPage && isActiveRepeatableItem && (
				<FormPage
					field={field}
					childrenFields={childrenFields}
					onSubmit={onSubmit}
					setFilterModalIsOpen={setFilterModalIsOpen}
					isRepeatableItemPage={true}
				/>
			)}
		</>
	);
};

const propsAreEqual = (
	prevProps: Readonly<PropsWithChildren<IProps>>,
	nextProps: Readonly<PropsWithChildren<IProps>>,
) => prevProps.field?.name === nextProps.field?.name;
const Memoized = memo(FormPage, propsAreEqual);
export default Memoized;
