import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import get from "lodash.get";
import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import Form, { FormField } from "models/Form";
import { DottedFieldPath, FormEngine, dotPath } from "@arup-group/dhub-forms-engine";
import FormRecord, { FormValues, IDataValue } from "models/FormRecord";
import { useAppSelector } from "store";

export const FilterFormContext = createContext<ReturnType<typeof useFilterForm> | undefined>(undefined);

const unsetDefaultValues = (fields: FormField[]): FormField[] => {
	return fields.map((field) => {
		if (field?.children && field.children.length)
			return {
				...field,
				defaultValue: undefined,
				children: unsetDefaultValues(field.children),
			};
		return { ...field, defaultValue: undefined };
	});
};

export const useFilterForm = (
	formEngine: FormEngine,
	record: FormRecord,
	form: Form,
	siblingRecords: FormRecord[] = [],
	initialFilters: FormValues,
) => {
	const { projectRef, assetId } = useParams<{ projectRef: string; assetId: string }>();
	const history = useAppSelector((state) => state.history.list);
	const [changes, setChanges] = useState<Set<string>>(new Set());

	const engine = useMemo(() => {
		const data = cloneDeep(formEngine.getData());
		const fields = unsetDefaultValues(cloneDeep(form.fields));
		set(data, history, [{ id: "filter", ...initialFilters }]);

		for (let i = history.length; i > 0; i--) {
			const subArray = history.slice(0, i);
			if (!isNaN(parseInt(history[i - 1]))) {
				set(data, subArray.slice(0, i - 1), [get(data, subArray)]);
			}
		}
		return new FormEngine(
			projectRef,
			assetId,
			{ ...form, fields },
			[{ ...record, data }, ...siblingRecords],
			true,
			true,
		);
	}, [projectRef, assetId, form.id, record.id]);

	const methods = useForm({
		reValidateMode: "onBlur",
	});

	const applyChange = useCallback(
		(
			fieldName: DottedFieldPath,
			value: IDataValue,
			options?: Partial<{
				shouldValidate: boolean;
				shouldTouch: boolean;
				shouldDirty: boolean;
				shouldSetSelf: boolean;
			}>,
		) => {
			if (options?.shouldSetSelf ?? true) methods.setValue(fieldName, value, options);
			const { data: newData, valueChanges: newChanges } = engine.digestSingleChange(fieldName, value);
			// Slicing the first change out since that's already processed by react-hook-forms
			for (const change of newChanges.slice(1)) {
				const newValue = get(newData, change);
				methods.setValue(change, newValue, {
					shouldDirty: options?.shouldDirty ?? true,
					shouldTouch: options?.shouldTouch ?? true,
					shouldValidate: options?.shouldValidate ?? true,
				});
			}
			setChanges((curr) => new Set([...curr, ...newChanges]));
		},
		[],
	);

	// Initializes form on first render
	useEffect(() => {
		methods.reset(engine.getData());
	}, []);

	useEffect(() => {
		const subscription = methods.watch((data, { name, type }) => {
			if (!name) return;
			// console.log("Detected change in field:", name, data, type);

			if (type === undefined) {
				// This is intended to short circuit changes triggered by this very mechanism and not controlled fields,
				// avoiding an otherwise infinte loop
				return;
			}

			const value = get(data, name) as IDataValue;
			applyChange(name, value, { shouldSetSelf: false });
		});
		return () => subscription.unsubscribe();
	}, []);

	return { engine, ...methods, changes: [...changes], applyChange };
};

export const useFilterFieldCtx = (field: FormField) => {
	const history = useAppSelector((state) => state.history.list);
	const formattedHistory = history.map((it) => (!isNaN(parseInt(it)) ? "0" : it));
	const ctx = useContext(FilterFormContext);
	if (!ctx) throw new Error("Form context not available");

	const initDig = ctx.engine.getDigestedField(field.name, [...formattedHistory, "0"]);
	const [state, setState] = useState({
		dig: initDig,
		isCompleted: false,
	});

	const { dig } = state;
	if (!dig) {
		throw new Error("Digested field not found");
	}

	useEffect(() => {
		const digestedFieldLibrary = ctx.engine.getDigestedFieldLibrary();
		const dottedPath = dotPath(dig.bakedPath);
		ctx.engine.subscribe(dottedPath, () => {
			setState({
				dig: digestedFieldLibrary[dottedPath],
				isCompleted: false,
			});
		});
		return () => ctx.engine.unsubscribe(dottedPath);
	}, [ctx.engine]);

	return {
		dig: { ...dig, required: false, hardRequired: false },
		isCompleted: false,
		currentValue: undefined,
		items: [],
		control: ctx.control,
	};
};
