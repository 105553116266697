import React, { PropsWithChildren, useMemo, useState } from "react";

import FiltersModal from "components/modals/FiltersModal";
import Form from "models/Form";
import FormRecord from "models/FormRecord";

import FormPage from "./components/FormPage";
import { Topbar } from "./components/Topbar/Topbar";
import { TopbarProps } from "./components/Topbar/TopbarProps";
import { FormContext, useSmartForm } from "./hooks/useSmartForm";
import { useFormErrorsCheck } from "./hooks/useFormErrorsCheck";
import { getPageFields } from "./utils/getPageFields";
import { SubmitFormFn } from "views/RecordView/RecordView";

interface IProps extends PropsWithChildren {
	form: Form;
	record: FormRecord;
	siblingRecords: FormRecord[];
	onSubmit: SubmitFormFn;
	exitForm: () => void;
	onPrint: () => void;
	TopbarComponent?: React.FC<TopbarProps>;
}

type PropsType = IProps;

const FormComponent: React.FC<PropsType> = (props) => {
	const { form, record, siblingRecords, onSubmit, exitForm, onPrint, TopbarComponent = Topbar } = props;

	const { engine, changes, ...methods } = useSmartForm(form, record, siblingRecords);
	const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);

	useFormErrorsCheck(form, methods.formState);

	const ctx = useMemo(() => ({ engine, changes, ...methods }), [changes.length, methods.formState.isDirty]);

	const pages = getPageFields(form.fields, []);

	return (
		<FormContext.Provider value={ctx}>
			<TopbarComponent formFields={form.fields} exitForm={exitForm} onSubmit={onSubmit} onPrint={onPrint} />
			<form
				id={record.id}
				style={{ position: "relative", height: "calc(100% - 3rem)", backgroundColor: "transparent" }}
			>
				<FormPage childrenFields={form.fields} onSubmit={onSubmit} setFilterModalIsOpen={setFilterModalIsOpen} />
				{pages.map((it) => (
					<FormPage
						key={it.name}
						field={it}
						childrenFields={it.children || []}
						onSubmit={onSubmit}
						setFilterModalIsOpen={setFilterModalIsOpen}
					/>
				))}
				{props.children}
				{/* This button's sole purpose is to disable form submit on enter, which can be annoying here */}
				<button type="submit" disabled style={{ display: "none" }} />
			</form>
			<FiltersModal
				isOpen={filterModalIsOpen}
				onClose={() => {
					setFilterModalIsOpen(false);
				}}
				formEngine={ctx.engine}
				record={record}
				form={form}
				siblingRecords={siblingRecords}
			/>
		</FormContext.Provider>
	);
};

export default FormComponent;
