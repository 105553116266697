import { IonButton, IonContent, IonItem, IonList, IonModal, IonText, useIonLoading } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { dbPromise } from "../../../services/idb";

interface IProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
}
type PropsType = IProps;

const ConfirmDeleteLocal: React.FC<PropsType> = (props) => {
	const { isOpen, setIsOpen } = props;
	const { t, i18n } = useTranslation();
	const [present, dismiss] = useIonLoading();
	const history = useHistory();

	const deleteLocalData = async () => {
		present({
			message: "Deleting...",
		});
		try {
			await (await dbPromise).clear("forms");
			await (await dbPromise).clear("projects");
			await (await dbPromise).clear("assets");
			await (await dbPromise).clear("records");
			await (await dbPromise).clear("images");
			await caches.delete("dhub-forms-images");
			localStorage.clear();
			sessionStorage.clear();
		} catch (e) {
			console.error(e);
		} finally {
			dismiss();
			setIsOpen(false);
			history.push("/");
			document.location.reload();
		}
	};

	return (
		<IonModal isOpen={isOpen}>
			<IonContent forceOverscroll={false}>
				<IonList
					lines="none"
					style={{
						marginTop: "auto",
						marginDown: "auto",
					}}
				>
					<IonItem lines="none">
						<IonText
							style={{
								marginLeft: "auto",
								marginRight: "auto",
							}}
						>
							{i18n.format(t("sure_question"), "capitalize")}
						</IonText>
					</IonItem>
					<IonItem lines="none">
						<IonButton
							expand="block"
							onClick={deleteLocalData}
							style={{
								height: "2.5rem",
								width: "16.5rem",
								borderRadius: "1.25rem",
								marginLeft: "auto",
								marginRight: "auto",
							}}
						>
							{i18n.format(t("delete_confirm"), "capitalize")}
						</IonButton>
					</IonItem>
					<IonItem>
						<IonButton
							onClick={() => setIsOpen(false)}
							expand="block"
							style={{
								height: "2.5rem",
								width: "16.5rem",
								borderRadius: "1.25rem",
								marginLeft: "auto",
								marginRight: "auto",
							}}
						>
							{i18n.format(t("cancel"), "capitalize")}
						</IonButton>
					</IonItem>
				</IonList>
			</IonContent>
		</IonModal>
	);
};

export default ConfirmDeleteLocal;
